@use 'assets/styles/utils/helper' as h;

.links {
  text-align: center;
}

.field {
  position: relative;
  width: 100%;
}

.error {
  margin-top: h.rem(5px);
  width: 100%;
}
