@use 'assets/styles/utils/helper' as h;

:root {
  --button-font-family: var(--founders-cond);
  --button-font-size: var(--text-16);
  --button-font-weight: 400;
  --button-text-color: var(--default-color);
  --button-border-color: var(--default-color);
  --button-text-hover-color: var(--white);
  --button-border-hover-color: var(--default-color);
  --button-background-color: var(--default-color);
  --button-text-color--darkBackground: var(--white);
  --button-border-color--darkBackground: var(--white);
  --button-text-hover-color--darkBackground: var(--grey-70);
  --button-text-background-color--darkBackground: var(--white);
  --button-text-color--inverted: var(--white);
  --button-text-hover-color--inverted: var(--default-color);
  --button-active-state: var(--default-active-opacity);
  --pancake-key-updates-color: #{h.$news-red-30};
  --pancake-key-updates-border-color: #{h.$news-red-30};
  --pancake-key-updates-bg-gradient-color: #{h.$news-red-30};
}

body.eonline {
  --button-font-family: var(--primary-font);
  --button-font-weight: var(--semibold);
}

body.today {
  --button-text-hover-color--darkBackground: var(--default-color);
}

body.telemundo,
body.entretenimiento,
body.shows {
  --pancake-key-updates-color: var(--default-color);
  --pancake-key-updates-border-color: var(--default-color);
  --pancake-key-updates-bg-gradient-color: var(--default-color);
}

body.select {
  --button-text-color: var(--blue-70);
  --button-border-color: var(--blue-70);
  --button-font-size: #{h.rem(16px)};
  --button-font-weight: 900;
  --button-font-family: var(--lato);
}
