@use 'assets/styles/utils/helper' as h;

.container {
  background: radial-gradient(rgba(255, 241, 236, 1) 4px, transparent 4px), rgba(255, 249, 245, 1);
  background-size: 16px 16px;
  min-height: h.rem(543px);
  display: flex;
  padding: h.rem(20px 0);

  @include h.breakpoint-m {
    padding: h.rem(30px 0);
  }

  @include h.breakpoint-l {
    padding: h.rem(40px 0);
  }

  @include h.breakpoint-x {
    padding: h.rem(80px 0);
  }
}

.title {
  color: var(--primary-color);
  font-family: var(--primary-font);
  font-size: h.rem(28px);
  font-weight: normal;
  text-align: center;
  margin: h.rem(5px 0 12px);

  @include h.breakpoint-l {
    font-size: h.rem(40px);
    margin-bottom: h.rem(16px);
  }
}

.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--secondary-font);
  margin-bottom: auto;

  @include h.breakpoint-m {
    max-width: h.rem(315px);
    padding: 0;
    width: 50%;
    margin-right: h.rem(15px);
  }

  @include h.breakpoint-l {
    width: 50%;
    min-width: h.rem(450px);
    padding: h.rem(0 25px);
  }

  h3 {
    font-size: h.rem(20px);
    font-weight: normal;
    line-height: 1.5;
    margin-top: 0;
    text-align: center;
    padding: h.rem(0 16px);
    margin-bottom: h.rem(12px);

    @include h.breakpoint-l {
      font-size: h.rem(24px);
      padding: 0;
      margin-bottom: h.rem(16px);
    }
  }

  span.iconSize {
    min-height: h.rem(24px);
    height: auto;
  }
}

.perks {
  max-width: h.rem(340px);
}

.perk {
  display: flex;
  align-items: center;
  line-height: 1.5;

  &:not(:last-of-type) {
    margin-bottom: h.rem(16px);
  }

  :global(.icon) {
    font-size: h.rem(40px);
    margin-right: h.rem(12px);
    color: #ff503c;
    min-width: h.rem(40px);
    text-align: center;

    &::before {
      line-height: h.rem(40px);
    }
  }

  :global(.icon.icon-save-filled) {
    font-size: h.rem(24px);
  }
}

.icon {
  margin-right: h.rem(12px);
  fill: #ff503c;
}

.screenContent {
  background-color: var(--white);
  margin: h.rem(0 20px);
  padding: h.rem(16px);

  @include h.breakpoint-m {
    margin: auto;
    padding: h.rem(20px);
    max-width: none;
  }
}

.wrapperLandingPage {
  display: flex;
  flex-direction: column-reverse;

  @include h.breakpoint-m {
    flex-direction: row;
  }
}
