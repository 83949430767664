@use 'assets/styles/utils/helper' as h;

// TEMP STYLES to be removed after bypass is no longer needed
.temp {
  margin: 0 auto;
  width: 300px;
  text-align: center;

  p {
    margin: 5px 0;
  }

  dialog & {
    position: absolute;
    top: -55px;
    background-color: var(--white);
  }

  select {
    color: var(--black);
  }
}

:global(.gated-content--wrapper) {
  margin-bottom: h.rem(45px);
}

.wrapper {
  position: relative;
  overflow: hidden;
  margin: auto;
  color: var(--grey-70);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--secondary-font);
  min-height: h.rem(543px);
  background-color: var(--accent-orange-light);

  :global(.account--auth--landing-page) & {
    background-color: transparent;
    min-height: unset;
    margin: 0;
    align-self: stretch;
    width: 100%;

    @include h.breakpoint-m {
      width: h.rem(332px);
    }
  }

  input {
    display: block;
    position: relative;
    line-height: h.rem(53px);
    width: h.rem(280px);
    margin: 0;
    border-radius: 0;
    border-width: 1px;
    border-style: solid;
    border-color: var(--grey-40);
    padding: h.rem(0 10px);
    font-size: h.rem(18px);

    :global(.account--auth--landing-page) & {
      width: 100%;
      max-width: h.rem(300px);
      margin: 0 auto auto;

      @include h.breakpoint-m {
        max-width: none;
      }
    }
  }

  .checkboxError[type='checkbox'],
  .errorInput {
    border-color: #ad0e00;
  }

  .errorMessage {
    font-size: h.rem(12px);
    font-weight: 600;
    color: #ad0e00;
    margin: h.rem(5px) 0 0;
    width: 100%;
    max-width: h.rem(300px);
    text-align: left;

    :global(.icon) {
      font-size: h.rem(14px);
      margin: h.rem(0 1px 1px 0);
      line-height: h.rem(15px);
    }

    &.standaloneError {
      margin-top: h.rem(10px);
      text-align: center;
      white-space: normal;

      :global(.icon) {
        margin-top: 1.5px;
        align-self: start;
        margin-right: 0;
      }
    }

    :global(.account--auth--landing-page) & {
      align-items: center;
      align-self: flex-start;
      line-height: 1.5;
      margin: auto;
      display: flex;
      font-weight: 600;

      &.standaloneError {
        margin-top: h.rem(10px);
      }
    }
  }

  .termsContainer {
    font-size: h.rem(12px);
    line-height: 1.25;
    max-width: h.rem(300px);
    padding-top: h.rem(20px);

    a {
      text-decoration: underline;
      font-weight: 700;
    }

    .errorMessage {
      margin: 0 0 h.rem(5px);
    }
  }

  .errorMessageEmail {
    margin-left: h.rem(20px);
  }

  input[type='checkbox'] {
    appearance: none;
    display: flex;
    height: h.rem(30px);
    max-width: h.rem(30px);
    background-color: var(--white);
    border: 1px solid var(--grey-40);
    border-radius: 0;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
  }

  input[type='checkbox']::before {
    height: 30px;
    width: 30px;
    transform: scale(0);
    color: var(--black);
  }

  input[type='checkbox']:checked::before {
    content: url('~assets/icons/check.svg');
    transform: scale(1) translateY(50%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -50%;
  }

  .checkboxError[type='checkbox'] {
    border-color: #ad0e00;
  }

  .formSubmitBtn {
    background: transparent;

    &:disabled {
      background: var(--grey-80);
    }
  }

  h2 {
    font-family: var(--primary-font);
    font-weight: 400;
    font-size: h.rem(24px);
    text-align: center;
    line-height: 1.5;
    padding: 0;
    margin: 0;
    max-width: h.rem(400px);

    @include h.breakpoint-m {
      font-size: h.rem(28px);
    }

    @include h.breakpoint-l {
      max-width: none;
    }
  }
}

.screen {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: h.rem(30px);
  height: 100%;
  flex: 1;
  max-width: h.rem(375px);

  @include h.breakpoint-m {
    max-width: none;
  }

  :global(.account--auth--landing-page) & {
    background-color: var(--accent-orange-light);
    padding: h.rem(20px);
    height: auto;
    min-height: 100%;
    width: 100%;
    margin: 0 auto h.rem(16px);
    align-self: stretch;

    @include h.breakpoint-m {
      margin-bottom: 0;
    }
  }
}

.wrapper__fluid {
  width: 100%;
  max-width: 100%;
}

.wrapper__fixed {
  width: 100%;

  @include h.breakpoint-m {
    width: h.rem(375px);
  }
}

.label {
  display: block;
  text-align: center;
  margin: h.rem(20px 0 5px 0);
  font-size: h.rem(18px);
  line-height: 1.5;
  font-weight: 400;

  :global(.account--auth--landing-page) & {
    margin: h.rem(15px 0 7px);
  }
}

.description {
  text-align: center;
  font-size: h.rem(18px);
  line-height: 1.5;
  margin: h.rem(20px 0 0);
  cursor: pointer;

  :global(.account--auth--landing-page) & {
    margin: h.rem(5px 0 0);
  }
}

.eye {
  width: h.rem(44px);
  position: absolute;
  right: h.rem(2px);
  top: h.rem(2px);
  bottom: h.rem(2px);
  border: none;
  background: var(--white);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: h.rem(3px);
}

input::-webkit-credentials-auto-fill-button {
  margin-right: h.rem(30px);
  margin-top: h.rem(1px);
  position: relative;
  z-index: 1;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  :global(.account--auth--landing-page) & {
    width: 100%;
  }
}

.buttonLink {
  display: inline-block;
  background: transparent;
  border: none;
  color: var(--black);
  font-size: h.rem(16px);
  font-weight: 700;
  line-height: 150%;
  padding: h.rem(10px 0);
  margin: h.rem(10px 0 -10px);
  text-decoration: underline;
  text-decoration-color: var(--accent-orange);
  text-decoration-thickness: h.rem(2px);
  text-underline-offset: h.rem(4px);
  position: relative;
  transition: color 0.175s;

  &:hover {
    color: var(--primary-color);
  }
}

:global(.account--auth--landing-page) button.button {
  width: auto;
  min-width: h.rem(170px);
  font-size: h.rem(19px);
  padding: h.rem(0 30px);
}

.userEmail {
  font-size: h.rem(18px);
  font-weight: bold;
  line-height: 1.5;
  margin: h.rem(10px 0 5px);

  :global(.account--auth--landing-page) & {
    margin: h.rem(5px 0);
  }
}

.checkboxContainer {
  display: flex;
  gap: h.rem(11px);
  max-width: 280px;

  p {
    margin-bottom: 0;

    &:first-child {
      margin-top: 0;
    }
  }

  :global(.account--auth--landing-page) & {
    max-width: none;

    a:hover {
      color: var(--primary-color);
    }
  }
}

.loader {
  position: absolute;
  z-index: 10;
  top: 100px;
  left: calc(50% - 25px);
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid var(--accent-orange);
  border-right-color: transparent;
  animation: l2 1s infinite linear;
}

@keyframes l2 {
  to {
    transform: rotate(1turn);
  }
}
