@use 'assets/styles/utils/helper' as h;

.smallTxt {
  font-size: h.rem(12px);
  text-align: center;
  margin: h.rem(5px 0 0);

  :global(.authentication--landing--page) & {
    margin-bottom: h.rem(5px);
  }
}

.error {
  margin-top: h.rem(5px);
  width: 100%;
}

.userEmail {
  margin-top: 0;
}
