@use 'assets/styles/utils/helper' as h;

.content {
  position: relative;
  z-index: 1;
  width: auto;
  margin-bottom: 0;

  :global(.account--auth--landing-page) & {
    width: 100%;
    margin-bottom: auto;
  }
}

.circleWrapper {
  opacity: 0.3;
  position: absolute;
  width: h.rem(320px);
  z-index: 0;

  svg {
    width: 100%;
    aspect-ratio: 366 / 176;
  }

  &.circleLeft {
    left: 0;
    bottom: 0;
    transform: rotate(180deg) translateX(50%);
  }

  &.circleRight {
    top: 0;
    right: 0;
    transform: translateX(50%);
  }

  :global(.account--auth--landing-page) & {
    display: none;
  }
}

.separator {
  width: 100%;
  text-align: center;
  background-color: var(--accent-orange);
  height: 1px;
  margin: h.rem(30px) 0;
  line-height: 0;

  :global(.account--auth--landing-page) & {
    margin: h.rem(22px) 0;
  }

  span {
    user-select: none;
    font-size: h.rem(14px);
    font-weight: 700;
    background-color: var(--accent-orange-light);
    padding: 0 0.5em;
  }
}

.bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error {
  margin-top: h.rem(5px);
  width: 100%;
}
