@use 'assets/styles/utils/helper' as h;

h2.heading {
  text-transform: uppercase;
  font-weight: 700;
  font-size: h.rem(18px);
  font-family: var(--secondary-font);
  margin: h.rem(8px 0 7px 0);
  vertical-align: top;
}

.description {
  text-align: center;
  font-size: h.rem(18px);
  line-height: 1.5;
  margin: h.rem(20px 0 0);
}

.submitButton {
  padding: 0;
  margin-top: h.rem(20px);
  width: h.rem(200px);
  font-size: h.rem(20px);
}
