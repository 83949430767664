@use 'assets/styles/utils/helper' as h;

.socialLogin {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: h.rem(12px);

  button {
    transition: background-color 0.15s;

    &:hover {
      background-color: var(--grey-20);
    }
  }

  :global(.account--auth--landing-page) & {
    gap: h.rem(15px);
    width: 100%;
    max-width: h.rem(300px);

    @include h.breakpoint-m {
      max-width: none;
    }
  }
}
