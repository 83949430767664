@use 'assets/styles/utils/helper' as h;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: h.rem(30px);
}

.NBCUniLogoStyles {
  width: h.rem(282px);
  height: h.rem(42.3px);
  margin-bottom: h.rem(10px);
}

.subHeaderText {
  font-family: var(--secondary-font);
  font-weight: 400;
  font-size: h.rem(16px);
  line-height: h.rem(24px);
  align-items: center;
  letter-spacing: 0;
  margin: 0;
}

.subHeaderTextMargin {
  margin-bottom: h.rem(2.5px);
}

.rowsLogos {
  margin: h.rem(10px);
  display: flex;
  flex-direction: row;
  margin-top: h.rem(19.5px);
  gap: h.rem(10px);
  margin-bottom: 0;
  object-fit: contain;
  align-items: center;
}

.circlesForLogos {
  background-color: var(--white);
  height: h.rem(68.26px);
  width: h.rem(68.26px);
  border-radius: h.rem(40px);
  position: relative;
  margin-bottom: 0;
}

.logoImages {
  object-fit: contain;
  margin-top: h.rem(10px);
  height: h.rem(49px);
  width: h.rem(69px);
}

.NBCOlympics_style {
  height: h.rem(36px);
  width: h.rem(69px);
  margin-top: h.rem(16px);
  object-fit: contain;
}

.ENews_style {
  height: h.rem(63px);
  width: h.rem(70px);
  margin-top: h.rem(3px);
  object-fit: contain;
}

.StartToday_style {
  height: h.rem(70px);
  width: h.rem(68px);
  margin-top: 0;
}
