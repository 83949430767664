@use 'assets/styles/utils/helper' as h;

.passwordErrorContainer {
  display: flex;
  width: 100%;
  max-width: 300px;
  margin: h.rem(5px 0);
  font-size: h.rem(12px);
}

.passwordErrorContainerWrap {
  display: flex;
  flex-direction: column;
}

.criteria {
  font-size: h.rem(12px);
  line-height: 1.5;
  margin: 0;
  display: flex;
  align-items: center;

  &.success {
    margin: h.rem(4px 0 2px -3px);
  }

  &.error {
    color: #ad0200;
    font-weight: 600;
  }

  :global(.icon) {
    min-width: h.rem(13px);
    line-height: h.rem(14px);
    font-size: h.rem(14px);
    margin: h.rem(0 1px 2px -3px);
    text-align: center;

    &:global(.icon-check) {
      margin-right: h.rem(2px);
      font-size: h.rem(12px);
    }
  }
}

.termsErrorContainer .criteria.error {
  :global(.account--auth--landing-page) & {
    margin-bottom: 0;
  }
}

.field {
  position: relative;
  width: 100%;
}
