@use 'assets/styles/utils/helper' as h;

.backButton {
  border: none;
  background-color: transparent;

  :global(.account--auth--landing-page) & {
    &:hover {
      color: var(--primary-color);

      path {
        stroke: var(--primary-color);
      }
    }
  }
}

.back {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: h.rem(24px);
  height: h.rem(24px);
  top: h.rem(14px);
  left: h.rem(6px);
  z-index: 2;
  box-sizing: content-box;
  border: none;
  background-color: transparent;
  color: var(--back);

  :global(.account--auth--landing-page) & {
    margin-bottom: h.rem(10px);
    position: static;
    width: auto;
    height: auto;

    svg {
      margin: h.rem(4px 5px 0 0);
    }
  }
}

.backText {
  font-size: h.rem(12px);
  text-decoration: underline;
  text-decoration-color: var(--accent-orange);
  text-decoration-thickness: h.rem(2px);
  text-underline-offset: h.rem(4px);
}
