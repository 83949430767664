@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.animated-ghost-button {
  font-family: var(--button-font-family);
  line-height: var(--leading-100);
  font-size: var(--button-font-size);
  font-weight: var(--button-font-weight);
  padding: var(--space-24) var(--space-4);
  background-position: -500px 0;
  background-repeat: no-repeat;
  transition: background-position ease 0.2s;
  color: var(--button-text-color);
  border: 1px solid var(--button-border-color);
  background-image: linear-gradient(35deg, var(--button-background-color) 50%, transparent 50%);
  text-transform: uppercase;
  display: block;
  text-align: center;
  width: 100%;

  &:hover {
    background-size: 325% 100%;
    background-position: 0 0;
    color: var(--button-text-hover-color);
    border: 1px solid var(--button-border-hover-color);
  }

  &.nl-signup-input__button--rail {
    background-color: var(--button-border-hover-color);

    &:hover {
      background-image: linear-gradient(35deg, var(--white) 50%, transparent 50%);
    }
  }

  &:active {
    opacity: var(--button-active-state);
  }

  &:focus {
    outline: none;
  }

  .button-hover-animation__button-icon::before {
    font-size: var(--button-font-size);
    font-weight: var(--semibold) !important;
    line-height: var(--text-16);
  }

  .button-hover-animation__button-icon.icon-angle-down {
    margin-left: var(--space-8);
  }

  .button-hover-animation__button-icon.icon-angle-down.up {
    margin-left: var(--space-8);
    transform: rotate(180deg);
  }

  .pancake__key-updates & {
    color: var(--pancake-key-updates-color);
    border: 1px solid var(--pancake-key-updates-border-color);
    background-image: linear-gradient(35deg, var(--pancake-key-updates-bg-gradient-color) 50%, transparent 50%);
  }

  .breaking &:hover,
  .pancake__key-updates &:hover {
    color: var(--white);
  }
}

.animated-ghost-button--small {
  padding: var(--space-16) var(--space-4);
}

.animated-ghost-button--darkBackground {
  color: var(--button-text-color--darkBackground);
  background-image: linear-gradient(35deg, var(--button-text-background-color--darkBackground) 50%, transparent 50%);
  border: 1px solid var(--button-border-color--darkBackground);

  &:hover {
    color: var(--button-text-hover-color--darkBackground);
  }
}

.animated-ghost-button--inverted {
  color: var(--button-text-color--inverted);
  background-color: var(--button-background-color);
  background-image: linear-gradient(35deg, transparent 50%, var(--button-background-color) 50%);

  &:hover {
    color: var(--button-text-hover-color--inverted);
    background-color: transparent;
  }
}
