@use 'assets/styles/utils/helper' as h;

.dialog {
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, .5);

  @include h.breakpoint-m {
    align-items: center;
  }
}

.content {
  position: relative;
}

.close {
  min-width: 44px;
  min-height: 44px;
  position: absolute;
  top: h.rem(8px);
  right: h.rem(4px);
  z-index: 2;
  border: none;
  background-color: transparent;
  color: transparent;
}
